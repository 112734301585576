import { Injectable } from '@angular/core';
import { INavContext, INavCommand } from './nav-context.model';
import { MessageService } from './message.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavContextService {

    private yearSelectedAction = new BehaviorSubject( (new Date()).getFullYear() );
    yearSelectedAction$ = this.yearSelectedAction.asObservable();

    public currentCtx: INavContext;


    constructor(private messageService: MessageService) {
        messageService.startService('NavContextService');
    }

    getYear(): number {
        return this.yearSelectedAction.getValue();
    }

    onYearSelected(year: number): void {
        this.yearSelectedAction.next(year);
    }


    clearActions(): void {
        this.currentCtx.leftCmd = null;
        this.currentCtx.rightCmd = null;
    }

    /* expressionchangedafterithasbeencheckederror

    Angular parte dalla root del DOM e scende tirando fuori i valori per tutte le cose di cui si fa il binding
    Se per caso, incontra un figlio che cambia un valore del padre, se la prende perchè per lui quel valore
    era considerato freezato .. quindi come fare a cambiare un valore già calcolato ?
    Una soluzione è tirarlo fuori dal lifecycle dell'aggiornamento ad esempio con setTimeout

    https://indepth.dev/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error/
    */

    updateAll(data: INavContext) {
        setTimeout(() => {
            this.currentCtx = data;
        });
    }
    updateRightCmds(cmds: INavCommand[]) {
        setTimeout(() => {
            this.currentCtx.rightCmd = cmds;
        });
    }
    updateTitle(title: string) {
        setTimeout(() => {
            this.currentCtx.title = title;
        });
    }
}
