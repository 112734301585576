import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { AuthService } from '../login/auth.service';
import { Observable } from 'rxjs';
import { MessageService } from '../core/message.service';
import packageInfo from '../../../package.json';
import { tap, catchError } from 'rxjs/operators';
import { Command } from 'protractor';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService, private messageService: MessageService) {
        this.messageService.startService('AccessTokenInterceptor');
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.messageService.addMessage(
            `AccessTokenInterceptor.intercept() - ${request.method} ${request.urlWithParams}`);

        // HttpHeader object immutable - copy values
        const headerSettings: {[name: string]: string | string[]; } = {};

        for (const key of request.headers.keys()) {
            headerSettings[key] = request.headers.getAll(key);
        }
        if ( this.auth.currentUser ) {
            headerSettings['Authorization'] = 'Bearer ' + this.auth.currentUser.access_token;
        }

        const noJSON = (request.url.endsWith('settings/db-export') && request.method === 'POST') ||
            request.url.endsWith('/upload');
        
        // Add JSon content type (but not for file upload - multipart/form-data)
        if (!noJSON) {
            headerSettings['Content-Type'] = 'application/json';
        } 

        const changedRequest = request.clone({
            setHeaders: headerSettings
        });

        return next.handle(changedRequest).pipe(
            tap((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponseBase) {
                    const ver = (<HttpResponseBase>evt).headers.get('gp-ver');
                    if (ver && ver !== packageInfo.version) {
                        this.messageService.addMessage('VERSION WARNING! SRV:' + ver + ' CLI:' + packageInfo.version);
                        this.auth.logout().subscribe( () =>
                            window.location.reload()
                        );
                    }
                }
            })
        );
    }
}
