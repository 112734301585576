<div class="container text-center">
    <form class="form-signin" #loginForm="ngForm" (ngSubmit)="login(loginForm)" autocomplete="off" novalidate>

        <img class="mt-5" src="assets/images/logo.png" alt="logo" width="200">
        <p class="mb-4">
            <small class="text-muted text-center font-italic">ver {{ appVersion }} </small>
        </p>
        
        <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
        
        <label for="userName" class="sr-only">User</label>
        <em *ngIf=" loginForm.controls.userName?.invalid && (
                    loginForm.controls.userName?.touched || mouseoverLogin)">Required</em>
        <input [(ngModel)]="userName" id="userName" name="userName"  type="text" class="form-control" placeholder="Nome" required autofocus>
        
        <label for="password" class="sr-only">Password</label>
        <em *ngIf=" loginForm.controls.password?.invalid && (
                    loginForm.controls.password?.touched || mouseoverLogin)">Required</em>
        <input [(ngModel)]="password" id="password" name="password"  type="password" class="form-control" placeholder="Password" required>
        
        <!--
        <div class="checkbox mb-3">
            <label>
                <input type="checkbox" value="remember-me"> Ricordami
            </label>
        </div>
        -->
        
        <button [disabled]="loginForm.invalid" class="btn btn-lg btn-primary btn-block" type="submit">Entra</button>
        

        <div *ngIf="loginInvalid" class="alert alert-danger mt-3">
            {{ errorMessage }}
        </div>
    </form>
    
</div>