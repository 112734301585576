<h1 mat-dialog-title>
    {{ data.title }}
</h1>

<div mat-dialog-content>
    <p *ngFor="let msg of data.messages">
        {{ msg }}
    </p>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button class="mr-1" (click)="cancel()">CANCELLA</button>
    <button mat-flat-button color="primary" (click)="ok()">OK</button>
</div>