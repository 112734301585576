import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { MessageService } from './message.service';
import { Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GpSrvService {

    SRV_BASE_URL: string;

    constructor(private messageService: MessageService) {
        messageService.startService('GpSrvService');
        this.SRV_BASE_URL = ConfigService.SRV_BASE_URL + 'v1/';
    }

    public handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            if (error instanceof HttpErrorResponse ) {
                this.messageService.error(`${operation}: ${error.message}`);
                if (error.error?.message) {
                    this.messageService.error(`${operation}: ${error.error.message} (${error.error.file} @ ${error.error.line})`);
                }
            } else {
                this.messageService.error(`${operation}: ${error.message}`);
            }
            return throwError(error);
            // return of(result as T);
        };
    }

    public handleError2(operation = 'operation', err: any): Observable<never> {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
        }
        this.messageService.error(`${operation}: ${err}`);
        return throwError(errorMessage);
    }

}
