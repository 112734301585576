import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class ConfigService {

    static DATE_FORMAT = 'YYYY-M-D';
    static SRV_BASE_URL = environment.apiUrl;

    static AUTH_ADMIN = 0;
    static AUTH_READ_ONLY = 1;
}
