import { Injectable } from '@angular/core';
import { IUser } from './user';
import { MessageService } from '../core/message.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from '../core/config.service';
import { environment } from 'src/environments/environment';
import { GpSrvService } from '../core/gp-srv.service';


@Injectable()
export class AuthService {
    currentUser: IUser;
    redirectUrl: string;

    constructor(
        private messageService: MessageService,
        private http: HttpClient,
        protected gpSrv: GpSrvService) {
        messageService.startService('AuthService');

        this.resetSecurityObject();

        if (!environment.production) {
            this.currentUser = {
                id: 1,
                name: 'Marco',
                mail: 'marco.bozzo@gmail.com',
                access_token: '1234567890abcdefghi...',
                expires_in: 123
            };
        }
    }

    isLoggedIn(): boolean {
        return !!this.currentUser;
    }

    login(userName: string, password: string): Observable<any> {
        if (!userName || !password) {
            this.messageService.addMessage('Please enter your userName and password');
            return;
        }
        this.resetSecurityObject();
        const url = this.gpSrv.SRV_BASE_URL + 'login';
        const item = { email: userName, password: password };

        return this.http.post(url, item)
            .pipe(
                tap( res =>
                    this.currentUser = {
                        id: res['user'].id,
                        name: res['user'].name,
                        mail: res['user'].email,
                        access_token: res['access_token'],
                        expires_in: res['expires_in']
                    })
            )
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        let errMsg = '';
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errMsg = 'A client-side or network error occurred:' + JSON.stringify(error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errMsg = `Backend returned code ${error.status}, ` + `body was: ` + JSON.stringify(error.error);
        }
        // return an observable with a user-facing error message
        return throwError(errMsg);
    }

    logout(): Observable<any> {
        return this.http.post( this.gpSrv.SRV_BASE_URL + 'logout', {})
            .pipe(
                tap( res => this.resetSecurityObject() )
            )
            .pipe(
                catchError( err =>
                    of(false) )
            );
    }

    resetSecurityObject(): void {
        this.currentUser = null;
    }

    getCurrentUser() {
        return this.currentUser;
    }
}
