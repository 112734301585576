import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { Error404Component } from './core/error-404.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './login/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';

// By default, Angular only contains locale data for en-US. If you set the value of LOCALE_ID
// to another locale, you must import locale data for that new locale.
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it-IT');

const routes: Routes = [
  {
      path: 'login',
      component: LoginComponent
  },
  {
      path: '',
      canLoad: environment.production ? [ AuthGuard ] : [],
      data: { preload: false },
      loadChildren: () => import('./features/feature.module').then(m => m.FeatureModule)
  },
  {
      path: '**',
      component: Error404Component
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    RouterModule.forRoot(routes, {
    // enableTracing
    // When true, log all internal navigation events to the console. Use for debugging.
    enableTracing: false,
    // anchorScrolling
    // When set to 'enabled', scrolls to the anchor element when the URL has a fragment. Anchor scrolling is disabled by default.
    // Anchor scrolling does not happen on 'popstate'. Instead, we restore the position that we stored or scroll to the top.
    anchorScrolling: 'enabled',
    // onSameUrlNavigation
    // Define what the router should do if it receives a navigation request to the current URL.
    // Default is ignore, which causes the router ignores the navigation. This can disable features such as a "refresh" button.
    onSameUrlNavigation: 'reload',
    // scrollPositionRestoration
    // Configures if the scroll position needs to be restored when navigating back.
    //      'disabled'- (Default) Does nothing. Scroll position is maintained on navigation.
    //      'top'- Sets the scroll position to x = 0, y = 0 on all navigation.
    //      'enabled'- Restores the previous scroll position on backward navigation, else sets the position to the anchor if one is
    //          provided, or sets the scroll position to [0, 0] (forward navigation). This option will be the default in the future.
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected'
})
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' }
  ],
  exports: [
    RouterModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    const ts = new Date();
    console.log('[' + ts.toLocaleTimeString() + '.' + ts.getMilliseconds() + '] Loading module: "AppModule"');
  }
}
