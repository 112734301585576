import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { NgForm } from '@angular/forms';
import packageInfo from '../../../package.json';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnDestroy {

    protected _destroy$ = new Subject<null>();
    userName: string;
    password: string;
    errorMessage: string;
    mouseoverLogin: boolean;
    loginInvalid = false;
    appVersion: string = packageInfo.version;

    constructor(private authService: AuthService, private router: Router) { }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    login(loginForm: NgForm) {
        this.errorMessage = '';
        this.loginInvalid = false;

        if (loginForm && loginForm.valid) {
            const userName = loginForm.form.value.userName;
            const password = loginForm.form.value.password;

            this.authService.login(userName, password)
            .pipe(takeUntil(this._destroy$))
            .subscribe({
                next: (resp: any) => {
                    if (!resp ) {
                        this.errorMessage = 'Invalid login info';
                        this.loginInvalid = true;
                    } else {
                        this.router.navigateByUrl('');
                    }
                },
                error: (err: string) => {
                    this.loginInvalid = true;
                    this.errorMessage = 'ERROR: ' + err;
                }
            });

            /*
            if (this.authService.redirectUrl) {
                this.router.navigateByUrl(this.authService.redirectUrl);
            } else {
                this.router.navigateByUrl('');
            }*/
        } else {
            this.errorMessage = 'Please enter a user name and password.';
        }
    }
}
