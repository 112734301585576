import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'currencyIta'
})
export class CurrencyItaPipe implements PipeTransform {

    constructor(private currencyPipe: CurrencyPipe) {        
    }
    
    transform(value: number): string {
        if(value && !isNaN(value)) {
            return this.currencyPipe.transform(value, 'EUR');
        }
        return '- €';
    }

}
