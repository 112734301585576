import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MessageService } from './message.service';
// import { SharedModule } from '../shared/shared.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { Error404Component } from './error-404.component';
import { NavContextService } from './nav-context.service';
import { AuthService } from '../login/auth.service';
import { AuthGuard } from '../login/auth-guard.service';
import { ConfigService } from './config.service';
import { GpSrvService } from './gp-srv.service';
import { ExcelServicesService } from './excel-services.service';

import * as moment from 'moment';
moment.locale('it');


// CoreModule will contain singleton services.
//
@NgModule({
  imports: [
    // SharedModule
  ],
  declarations: [
    Error404Component,
  ],
  exports: [
    Error404Component
  ],
  providers: [
    ConfigService,
    GpSrvService,
    MessageService,
    NavContextService,
    AuthService,
    AuthGuard,
    ExcelServicesService,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private messageService: MessageService) {
    messageService.loadModule('CoreModule');
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
