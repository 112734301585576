import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


export interface ConfirmDialogComponentData {
    title: string;
    messages?: string[];
}

@Component({
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {


    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogComponentData) {        
    }

    ok(): void {        
        this.dialogRef.close(true);
    }
    cancel(): void {
        this.dialogRef.close(false);
    }

}
