import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class MessageService {

    devMode: boolean;
    services = new Map();

    constructor() {
        this.devMode = environment.enableLog;
        this.startService('MessageService');
    }

    addMessage(message: string): void {
        if (!this.devMode) {
            return;
        }
        console.log(this.getTs() + message);
    }

    error(msg: string): void {
        console.error(`${this.getTs()}[ERR] ${msg}`);
    }

    loadModule(moduleName: string): void {
        // this.addMessage('Loading module: "' + moduleName + '"');
    }

    startService(serviceName: string): void {
        if (!this.devMode) {
            return;
        }
        // this.addMessage('Start service: "' + serviceName + '"');
        const instances = this.services.get(serviceName);
        if (instances !== undefined) {
            this.services.set(serviceName, instances + 1);
            if (serviceName === 'AccessTokenInterceptor') {
                console.warn(`Warning: servizio \'${serviceName}\' ha ${instances + 1} istanze !`);
            } else {
                console.error(`Error: servizio \'${serviceName}\' ha ${instances + 1} istanze !`);
            }
        } else {
            this.services.set(serviceName, 1);
        }
    }

    private getTs(): string {
        const ts = new Date();
        const dt = ts.toLocaleTimeString();
        const msec = ('00' + ts.getMilliseconds()).slice(-3);
        return `[${dt}.${msec}] `;
    }
}
